import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFilteredData, setLoading, setData, setError } from '../storage/store';
import { getMatches } from "../service/getMatches";
import { Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import '../css/liveSettings.css';
import axios from 'axios';

const LiveSettings = () => {
    const apiUrl = process.env.REACT_APP_APIURL; // Use dynamic API URL from environment variables
    const [searchId, setSearchId] = useState('');
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState({
        match_id: "",
        home_team: "",
        away_team: "",
        league: "",
        live_status: 1,
        match_date: "",
        match_time: ""
    });
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const dispatch = useDispatch();

    const fetchLiveMatch = async () => {
        try {
            const resLiveMatch = await axios.get(`${apiUrl}/getCurrentMatch/get-match`);
            const match = resLiveMatch.data[0]; // Assuming the first match in the response
            console.log("Selected match: ", match);
            const sched = new Date(match.match_date);
            const month = sched.getMonth();
            setSelectedMatch({
                match_id: match.match_id,
                home_team: match.home_team,
                away_team: match.away_team,
                league: match.league,
                live_status: match.live_status,
                match_date: months[month] + sched.getDate(),
                match_time: match.match_time
            });
        } catch (error) {
            dispatch(setError(error.toString()));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await getMatches();
                if (!response) {
                    throw new Error('Network Response was not OK');
                }
                dispatch(setData(response));
            } catch (error) {
                dispatch(setError(error.toString()));
            }
        };

        fetchData();
        fetchLiveMatch();
    }, [dispatch, apiUrl]); // Add a dependency array to run the effect once when the component mounts

    const { loading, error } = useSelector(state => state.data);
    const allMatches = useSelector(selectFilteredData);

    useEffect(() => {
        setFilteredMatches(allMatches);
    }, [allMatches]);

    const handleSearch = () => {
        const filtered = allMatches.filter(item => item.MatchID.includes(searchId));
        setFilteredMatches(filtered);
    };

    const handleSelectMatch = async (matchId) => {
        const match = filteredMatches.find(item => item.MatchID === matchId);

        try {
            // Send POST request to save the selected match
            await axios.post(`${apiUrl}/getCurrentMatch/save-match`, match);
            alert('Match saved successfully!');
            console.log(match)
            // Refetch the selected match after saving
            const sched = new Date(match.TimeStart);
            const month = sched.getMonth();
            setSelectedMatch({
                match_id: match.MatchID,
                home_team: match.Home,
                away_team: match.Away,
                league: match.League,
                live_status:  parseInt(match.IsLive) === match.NowPlaying ? 0 : 1,
                match_date: months[month] + sched.getDate(),
                match_time: sched.toLocaleTimeString([], { timeStyle: 'short', timeZone: 'Asia/Dhaka'})
            });
            console.log(selectedMatch)
        } catch (error) {
            console.error('Error saving match:', error);
            alert('Failed to save match');
        }
    };

    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    if (error) {
        return <div className='match-container'><div>Error: {error}</div></div>;
    }

    return (
        <div className='LiveSettings'>
            <div className='match-container'>
                {/* Selected Match Details */}
                {selectedMatch && selectedMatch.match_id && (
                    <div className="selected-match-details" style={{ margin: ' 20px 0', padding: '10px', border: '1px solid #ccc', borderRadius: "5px" ,backgroundColor: "#fff" }}>
                        <Typography variant="h6">Selected Match Details:</Typography>
                        <Typography><strong>Match ID:</strong> {selectedMatch.match_id}</Typography>
                        <Typography><strong>Home Team:</strong> {selectedMatch.home_team || 'N/A'}</Typography>
                        <Typography><strong>Away Team:</strong> {selectedMatch.away_team || 'N/A'}</Typography>
                        <Typography><strong>Date:</strong> {selectedMatch.match_date}</Typography>
                        <Typography>
                            <strong>Time:</strong> {
                              selectedMatch.match_time
                            }
                        </Typography>
                        <Typography><strong>League:</strong> {selectedMatch.league || 'N/A'}</Typography>
                        <Typography><strong>Live Status:</strong> {selectedMatch.live_status ? 'Live' : 'Offline'}</Typography>
                    </div>
                )}

                <div className='TableHeader'>
                    <div className="search-container">
                        <TextField
                            label="Search Match ID"
                            variant="filled"
                            value={searchId}
                            onChange={(e) => setSearchId(e.target.value)}
                        />
                        <Button variant="contained" sx={{ padding: "15px 20px" }} onClick={handleSearch}>Search</Button>
                    </div>
                </div>

                <TableContainer component={Paper} sx={{ borderRadius: "0px" }}>
                    <Table aria-label="match table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Match ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Home Team</TableCell>
                                <TableCell>Away Team</TableCell>
                                <TableCell>League</TableCell>
                                <TableCell>Live Status</TableCell>
                                <TableCell>Select</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredMatches.map(item => {
                                const isBroadcast = item.IsLive === item.NowPlaying.toString() ? 0 : 1;
                                const sched = new Date(item.TimeStart);
                                const month = sched.getMonth();
                                return (
                                    <TableRow key={item.MatchID}>
                                        <TableCell>{item.MatchID}</TableCell>
                                        <TableCell>{months[month]} {sched.getDate()}</TableCell>
                                        <TableCell>{sched.toLocaleTimeString([], { timeStyle: 'short', timeZone: 'Asia/Dhaka' })}</TableCell>
                                        
                                        <TableCell>{item.Home}</TableCell>
                                        <TableCell>{item.Away}</TableCell>
                                        <TableCell>{item.League}</TableCell>
                                        <TableCell>{isBroadcast ? 'Offline' : 'Live'}</TableCell>
                                        <TableCell>
                                            <Button variant="outlined" onClick={() => handleSelectMatch(item.MatchID)}>
                                                Select Match
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default LiveSettings;
