import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../css/banner.css";

const BannerSettings = () => {
    const [bannerImage, setBannerImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [buttonLink, setButtonLink] = useState('');
    const [bannerTextImage, setBannerTextImage] = useState(null);
    const [textPreview, setTextPreview] = useState(null);
    const [error, setError] = useState(null);
    const [existingImage, setExistingImage] = useState(null);
    const [existingTextImage, setExistingTextImage] = useState(null);
    const apiUrl = process.env.REACT_APP_APIURL;

    useEffect(() => {
        const fetchBannerSettings = async () => {
            try {
                const response = await axios.get(`${apiUrl}/banner/api/get-banner`);
                const { button_link, banner_image, banner_text_image } = response.data.data;

                setButtonLink(button_link || '');
                setPreview(`${apiUrl}${banner_image}`);
                setTextPreview(`${apiUrl}${banner_text_image}`);
                setExistingImage(banner_image);
                setExistingTextImage(banner_text_image);
            } catch (error) {
                console.error('Error fetching banner settings:', error);
            }
        };

        fetchBannerSettings();
    }, [apiUrl]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const maxSizeMB = 1;
        const maxSizeBytes = maxSizeMB * 1024 * 1024;

        if (file) {
            setError(null);

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const minWidth = 1440;
                const minHeight = 600;
                const maxWidth = 1920;
                const maxHeight = 700;

                if (img.width < minWidth || img.height < minHeight || img.width > maxWidth || img.height > maxHeight) {
                    setError(`Image dimensions should be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`);
                    setBannerImage(null);
                    setPreview(null);
                } else {
                    setBannerImage(file);
                    setPreview(URL.createObjectURL(file));
                    setExistingImage(null);
                }

                URL.revokeObjectURL(img.src);
            };
        } else {
            setBannerImage(null);
            setPreview(null);
            setError(null);
        }
    };

    const handleTextImageUpload = (event) => {
        const file = event.target.files[0];
        const maxSizeMB = 1;
        const maxSizeBytes = maxSizeMB * 1024 * 1024;

        if (file) {
            setError(null);

            if (file.size > maxSizeBytes) {
                setError(`Text image size should be less than ${maxSizeMB}MB.`);
                setBannerTextImage(null);
                setTextPreview(null);
                return;
            }

            setBannerTextImage(file);
            setTextPreview(URL.createObjectURL(file));
            setExistingTextImage(null);
        } else {
            setBannerTextImage(null);
            setTextPreview(null);
        }
    };

    const handleClearTextPreview = () => {
        setBannerTextImage(null);
        setTextPreview(null);
        setExistingTextImage(null);
    };

    const handleLinkChange = (event) => {
        setButtonLink(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('buttonLink', buttonLink);

        if (bannerImage) {
            formData.append('bannerImage', bannerImage);
        } else if (existingImage) {
            formData.append('existingImage', existingImage);
        }

        if (bannerTextImage) {
            formData.append('bannerTextImage', bannerTextImage);
        } else if (existingTextImage) {
            formData.append('existingTextImage', existingTextImage);
        }

        try {
            const response = await axios.post(`${apiUrl}/banner/api/banner-settings`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Banner settings saved:', response.data);
            alert('Banner settings saved successfully!');
        } catch (error) {
            console.error('Error saving banner settings:', error);
            alert('Failed to save banner settings.');
        }
    };

    return (
        <div className="banner-settings">
            <form onSubmit={handleSubmit}>
                <div className='banner-area'>
                    <div title='Click here to upload or update the banner'
                        className="upload-area bannerImg-area" 
                        onClick={() => document.getElementById('bannerImage').click()}
                    >
                        {preview ? (
                            <img src={preview} alt="Banner Preview" />
                        ) : (
                            <span>Click here to upload</span>
                        )}
                        <input 
                            type="file" 
                            id="bannerImage" 
                            accept="image/*" 
                            style={{ display: 'none' }} 
                            onChange={handleImageUpload} 
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}

                    <div title='Click here to upload or update the banner text image'
                        className="upload-area bannerText-area" 
                        onClick={() => document.getElementById('bannerTextImage').click()}
                    >
                        {textPreview ? (
                            <img src={textPreview} alt="Banner Text Image Preview" />
                        ) : (
                            <span>Click here to upload text image</span>
                        )}
                        <input 
                            type="file" 
                            id="bannerTextImage" 
                            accept="image/png" 
                            style={{ display: 'none' }} 
                            onChange={handleTextImageUpload} 
                        />
                         {/* Clear Text Image Button */}
                    {textPreview && (
                        <button type="button" className='btn-Clear' onClick={handleClearTextPreview}>
                            Clear Text Image
                        </button>
                    )}
                    </div>
                    
                   
                </div>
                
                {existingImage && (
                    <input 
                        type="hidden" 
                        name="existingImage" 
                        value={existingImage} 
                    />
                )}
                {existingTextImage && (
                    <input 
                        type="hidden" 
                        name="existingTextImage" 
                        value={existingTextImage} 
                    />
                )}

                <div className="input-section">
                    <label htmlFor="buttonLink">Match ID:</label>
                    <input 
                        type="text" 
                        id="buttonLink" 
                        value={buttonLink} 
                        onChange={handleLinkChange} 
                        placeholder="Enter Match ID" 
                    />
                    <button type="submit">Save</button>
                </div>
            </form>
        </div>
    );
};

export default BannerSettings;
