import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { setLoading, setData, setError, setCurrentLive  } from '../storage/store';
import { getMatches } from "../service/getMatches";
import { getCurrentLive } from '../service/getCurrentLive';
import '../css/live.css';
import CurrentMatch from '../component/currentMatch';
import LiveChat from '../component/livechat';
import { Typography } from '@mui/material';
import RecentMatches from '../component/recentMatches';
import { Helmet } from 'react-helmet';
import axios from 'axios';
const Live = () => {
    const apiUrl = process.env.REACT_APP_APIURL; // Use dynamic API URL from environment variables
    const dispatch = useDispatch();
    
    

    const [selectedMatch, setSelectedMatch] = useState({
        match_id: "",
        home_team: "",
        away_team: "",
        league: "",
        live_status: 1,
        match_date: "",
        match_time: ""
    });
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
     useEffect(() => {
         const fetchData = async () => {
             dispatch(setLoading(true));
             try {
                 const response = await getMatches()
                 if (response.length === null) {
                     throw new Error('Network Response was not OK')
                 }
                 const data = await response;
                 dispatch(setData(data)); // Dispatch action to set data in Redux store
             } catch (error) {
                 dispatch(setError(error.toString()));
             }
         };


         fetchData();

         const fetchLiveURL = async () => {
            dispatch(setLoading(true));
            try {
                const getLink = await getCurrentLive()
                if (getLink.length === null) {
                    throw new Error('Network Response was not OK')
                }
                const link = await getLink;
                dispatch(setCurrentLive({ urlink: link.urlLink, matchID: link.matchID}));
                sessionStorage.setItem("matchId",  link.matchID);
            }
            catch (error) {
                dispatch(setError(error.toString()));
            }
        }
        

        fetchLiveURL();
 
       
     }, [dispatch]);


 
    return (
        <>
        
        <Helmet>
                <title>Live Football Streaming - PBVSports</title>
                <meta name="description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
                <meta name="keywords" content="football, live streaming, sports, Vietnam, football leagues, live matches, football news, football highlights" />
                <meta name="author" content="PBVSports"/>
                <meta property="og:title" content="Live Football Streaming - PBVSports"/>
                <meta property="og:description" content="Stream live football matches and stay updated with the latest scores and news." />
                <meta property="og:image" content="URL_to_your_image" />
                <meta property="og:url" content="https://pbv88sports.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image"  />
                <meta name="twitter:title" content="Live Football Streaming - PBVSports" />
                <meta name="twitter:description" content="Stream live football matches and stay updated with the latest scores and news." />
                <meta name="twitter:image" content="URL_to_your_image" />
                <meta name="twitter:site" content="@yourtwitterhandle" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="live-container">
                <div className='live-wrapper'>


                    <CurrentMatch></CurrentMatch>


                    <LiveChat></LiveChat>
                    
                </div>
                <RecentMatches/>
            </div>
            
        </>
    );
};

export default Live;
