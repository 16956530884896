import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { getCurrentLive } from "../service/getCurrentLive";
import eyewatcher from "../assets/images/eye-watcher.svg";
import getSessionId from '../utils/getSessionId'; // Import getSessionId
import joinMatch from '../actions/joinMatch'; // Import joinMatch
import leaveMatch from '../actions/leaveMatch'; // Import leaveMatch
import fetchViewerCount from '../actions/fetchViewerCount'; // Import fetchViewerCount
import { setLoading, setError, setCurrentLive, currentLiveurl  } from '../storage/store';


const CurrentMatch = () => {

  
    const liveURL =  useSelector(state => state.data.currentLiveurl);
    const [activeUsers, setActiveUsers] = useState(1);
 
   


    const apiurl = process.env.REACT_APP_APIURL;

  

    console.log(liveURL)

    const sessionId = getSessionId(); // Get session ID using the utility
    const matchId = sessionStorage.getItem("matchId");

    sessionStorage.setItem("matchId", matchId);
    // Using joinMatch and leaveMatch from separate files
    const handleJoinMatch = useCallback(() => joinMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);
    const handleLeaveMatch = useCallback(() => leaveMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);

    useEffect(() => {
        const updateViewerCount = () => fetchViewerCount(apiurl, matchId, setActiveUsers); // Fetch viewer count

        const intervalId = setInterval(updateViewerCount, 5000);
        if (liveURL){
            handleJoinMatch().then(updateViewerCount);
        }
        

        return () => {
            clearInterval(intervalId);
           // handleLeaveMatch();
        };
    }, [handleJoinMatch, handleLeaveMatch, apiurl, matchId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            handleLeaveMatch();
            event.returnValue = '';
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleLeaveMatch();
            } else if (document.visibilityState === 'visible') {
                if (liveURL){
                    handleJoinMatch();
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleLeaveMatch, handleJoinMatch]);


    const toggleIframeFullScreen = () => {
        const iframe = document.getElementById("iframe");
        const fullscreenButton = document.querySelector(".iconfullscreen"); // Target the button
        const body = document.body;
    
        if (!iframe) {
            console.error("Iframe not found!");
            return;
        }
    
        // Check if Fullscreen API is supported (used for desktops)
        if (iframe.requestFullscreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullscreen || iframe.msRequestFullscreen) {
            if (!document.fullscreenElement) {
                // Enter Fullscreen API mode (desktop)
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.mozRequestFullScreen) { // Firefox
                    iframe.mozRequestFullScreen();
                } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                    iframe.webkitRequestFullscreen();
                } else if (iframe.msRequestFullscreen) { // IE/Edge
                    iframe.msRequestFullscreen();
                }
    
                // Add a class to indicate fullscreen mode on the button
                fullscreenButton.classList.add('in-fullscreen');
            } else {
                // Exit Fullscreen mode
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
    
                // Remove the fullscreen class from the button
                fullscreenButton.classList.remove('in-fullscreen');
            }
        } else {
            // Fallback to CSS-based fullscreen (for mobile)
            const isFullScreen = iframe.classList.contains('iframe-fullscreen');
            if (!isFullScreen) {
                // Add fullscreen class (mobile)
                iframe.classList.add('iframe-fullscreen');
                body.classList.add('iframe-fullscreen-mode');
                
                // Add the fullscreen class to the button
                fullscreenButton.classList.add('in-fullscreen');
            } else {
                // Remove fullscreen class
                iframe.classList.remove('iframe-fullscreen');
                body.classList.remove('iframe-fullscreen-mode');
                
                // Remove the fullscreen class from the button
                fullscreenButton.classList.remove('in-fullscreen');
            }
        }
    };
    
    
    
    window.addEventListener("orientationchange", () => {
        const iframe = document.getElementById("playframe");
        if (iframe && document.fullscreenElement) {
            iframe.style.width = window.innerWidth + "px";
            iframe.style.height = window.innerHeight + "px";
        }
    });
    
    
    return(
       
        <div className='video-wrapper'>
            <div className='video-container' id='iframe'>
                <div className='liveVisit'>
                    <small>LIVE</small>
                    <span><img src={eyewatcher} alt='watchers' /> {activeUsers ? activeUsers : 1}</span>
                </div>
                            
                {liveURL ? (
                    <iframe src={liveURL.urlink } style={{
                        display: 'block',
                        padding: '0px 0',
                        border: '1px solid #000',
                        backgroundColor: '#000',
                        width: '100%'
                    }} width="720" height="480" title='live stream' id="playframe" allowFullScreen allow='fullscreen'></iframe>
                ) : (
                    <div className="box-spinner"></div>
                )}
                <div onClick={toggleIframeFullScreen} className='fullscreenIcon'> <i className='iconfullscreen'></i></div>
                </div>
        </div>
    )
}
export default CurrentMatch;