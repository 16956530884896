import React, { useState, useEffect } from 'react';
import '../css/header.css'; // Import the CSS file for styling
import logo from '../assets/images/pbc-logo.png';
import Icon from '@mui/material/Icon';
import { Button, Box } from '@mui/material'; // Import Material UI components
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Header() {
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate(); // To handle navigation
    const location = useLocation(); // To get the current route
    const authToken = localStorage.getItem('authToken'); // Check if user is authenticated

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Remove token from localStorage
        navigate('/rs-pbv-login'); // Redirect to login page
    };

    useEffect(() => {
        if (menuActive) {
            document.getElementById("main-body").classList.remove('body-collapse');
        } else {
            document.getElementById("main-body").classList.add('body-collapse');
        }
    }, [menuActive]);

    return (
        <header className="header">
            <nav id='nav-main' className={`${menuActive ? 'active' : ''}`}>
                <div className='nav-links'>
                      {authToken && (
                        <>
                            <a href='/match-stats'>Dashboard</a>
                        </>
                      )}
                    {/* Conditionally render links based on whether the user is logged in */}
                    <a href='/'>Trang chủ</a>
                    <a href='/matches'>Trận đấu</a>
                    <a href='/live'>Sống</a>
                    <a href='/blog'>Tin tức và Sự kiện</a>

                    {/* Links shown only when the user is logged in */}
                    {authToken && (
                        <>
                            <a href='/blog-list'>Blogs</a>
                            <a href='/live-settings'>Live Settings                        </a>
                            <a href='/banner-settings'>Banner Settings                        </a>
                            {/* <a href='/banner'>Banner</a> */}
                        </>
                    )}
                </div>
            </nav>
            <div className={`nav ${menuActive ? '' : 'body-collapse'}`}>
                <div className='nav-bar'>
                    <div className='nav-button' onClick={toggleMenu}><Icon>menu</Icon></div>
                    <a href="/"> 
                        <img src={logo} alt="Company Logo" className="logo" />
                    </a>
                </div>
                {/* Conditionally render Logout Button */}
                {/* Show the logout button only if user is authenticated and not on the login page */}
                {authToken && location.pathname !== '/login' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                       
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </Box>
                )}
            </div>
        </header>
    );
}

export default Header;
