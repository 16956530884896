import React, { useEffect, useState } from 'react';
import blogData from '../assets/blog/data.json';
import '../css/blog.css'; // Import the CSS file for styling
import { Icon, FormControl, Select, MenuItem, Pagination } from "@mui/material";
import { Helmet } from 'react-helmet';
import matchBanner from '../assets/images/match-banner.png';
import axios from 'axios'; // For making API requests

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending
    const [currentPage, setCurrentPage] = useState(1); // State to track current page
    const [postsPerPage] = useState(6); // Number of posts per page
    const apiurl = process.env.REACT_APP_APIURL;

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${apiurl}/blogs`); // Adjust API endpoint
                setPosts(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(true);
            }
        };
        fetchBlogs();
    }, [sortOrder]);

    // Function to handle page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const truncateContent = (content, wordLimit) => {
        const words = content.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return content;
    };

    // Sort function that sorts based on the current sortOrder
    const sortPosts = (posts, order) => {
        return [...posts].sort((a, b) => {
            const dateA = new Date(a.dateCreated);
            const dateB = new Date(b.dateCreated);
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        });
    };

    // Handle sorting change from dropdown
    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    // Get current posts for the page
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortPosts(posts, sortOrder).slice(indexOfFirstPost, indexOfLastPost);

    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    return (
        <>
            <Helmet>
                <title>Football Blog - PBVSports</title>
                <meta name="description" content="Read the latest articles, news, and insights on football leagues and matches on the PBVSports blog. Stay informed with expert analysis and in-depth coverage." />
                <meta name="keywords" content="football blog, sports articles, football news, match analysis, football insights, PBVSports blog, Vietnam football" />
                <meta name="author" content="PBVSports" />
                <meta property="og:title" content="Football Blog - PBVSports" />
                <meta property="og:description" content="Explore the latest articles, news, and insights on football leagues and matches." />
                <meta property="og:image" content="URL_to_your_image" />
                <meta property="og:url" content="https://pbv88sports.com/blog" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Football Blog - PBVSports" />
                <meta name="twitter:description" content="Explore the latest articles, news, and insights on football leagues and matches." />
                <meta name="twitter:image" content="URL_to_your_image" />
                <meta name="twitter:site" content="@yourtwitterhandle" />
            </Helmet>
            <div className="blogBanner">
                <img src={matchBanner} alt='banner' />
            </div>
            <section className='blog-page'>
                <div className='wrapper'>
                    <div className='sort-dropdown'>
                        <FormControl>
                            Sort by
                            <Select
                                value={sortOrder}
                                onChange={handleSortChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Sort by Date' }} sx={{backgroundColor:"#fff", fontSize: "18px" }}
                            >
                                <MenuItem value="desc">Newest First</MenuItem>
                                <MenuItem value="asc">Oldest First</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='blogs'>
                        {currentPosts.map(post => (
                            <div key={post.id} className={`blog-post div${post.id}`}>
                                <div className='blog-inner'>
                                    <img src={`${apiurl}/uploads/${post.image || 'default.jpg'}`} alt={post.title || "Blog post image"} style={{ width: '100%', height: 'auto' }} />
                                    <div className='blog-content'>
                                        <h2>{post.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html:truncateContent(post.content, 8)}}></div>
                                        <a href={`/blog/${post.slug}`} >Read More <Icon>chevron_right</Icon> </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Pagination Component */}
                    <div className="pagination">
                        <Pagination
                            count={Math.ceil(posts.length / postsPerPage)} // Total number of pages
                            page={currentPage} // Current page
                            onChange={handlePageChange} // Function to handle page change
                            color="primary"
                            sx={{ marginTop: 2, justifyContent: 'center', display: 'flex' }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog;
