import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import blogData from '../assets/blog/data.json';
import '../css/blog.css';
import { Helmet } from 'react-helmet';
import axios from 'axios'; // For making API requests

const BlogDetail = () => {
    const { slug } = useParams(); // Get the blog post slug from the URL
    const [post, setPost] = useState(null);
    const [recentPosts, setRecentPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const apiurl = process.env.REACT_APP_APIURL;


    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${apiurl}/blogs`); // Adjust API endpoint
                setPost(response.data.find(p => p.slug === slug));
                setLoading(false);

                const recent = [...response.data]
                .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
                .slice(0, 5); 
                setRecentPosts(recent);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(true)
            }
        };


        fetchBlog();
    }, [slug]);


    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }
    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <>
        <Helmet>
            <title>{post.title}</title>
            <meta name="description" content={post.metaDescription} />
            <meta name="keywords" content={post.metaKeywords} />
            <meta name="author" content="PBVSports"/>
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.metaDescription} />
            <meta property="og:image" content={`${apiurl}/uploads/${post.image || 'default.jpg'}`} />
            <meta property="og:url" content={`https://pbv88sports.com/blog/${slug}`} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.title} />
            <meta name="twitter:description" content={post.metaDescription} />
            <meta name="twitter:image" content={`${apiurl}/uploads/${post.image || 'default.jpg'}`} />
            <meta name="twitter:site" content="@yourtwitterhandle" />
        </Helmet>
        
        <div className='blog-details'>
            <div className="blog-detail-page">
                <div className="main-content">
                    <img src={`${apiurl}/uploads/${post.image || 'default.jpg'}`} alt={post.title || "Blog post image"} style={{ width: '100%', height: 'auto' }} />
                    <div className='blog-detail-content'>
                        <h1>{post.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                    </div>
                </div>

                <div className="sidebar">
                    <h3>Recent Posts</h3>
                    <ul>
                        {recentPosts.map(rp => (
                            <li key={rp.id}>
                                <Link to={`/blog/${rp.slug}`}>
                                    <span><img src={`${apiurl}/uploads/${rp.image || 'default.jpg'}`} alt={rp.title || "Blog post image"} /></span>
                                    <span>{rp.title}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
};

export default BlogDetail;
