import { configureStore, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

// Define the initial state
const initialState = {
  data: [], // Initialize with an empty array
  loading: false,
  error: null,
  activeCategory: 'all', // Initial active category
  currentPage: 1, // Initialize currentPage
  currentLiveurl: null,
  
};

// Create a slice
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
      state.currentPage = 1; // Reset to the first page when the category changes
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCurrentLive: (state, action) => {
      state.currentLiveurl = action.payload;
      
    }
  },
});

// Export actions
export const { setLoading, setData, setError, setActiveCategory, setCurrentPage, setCurrentLive  } = dataSlice.actions;

export const selectCurrentPage = state => state.data.currentPage;

// League filters based on environment variables
const CricketleagueFilter = (process.env.REACT_APP_CRICKET_FILTER || '').split(',').filter(Boolean);
const BasketballleagueFilter = (process.env.REACT_APP_FOOTBALL_FILTER || '').split(',').filter(Boolean);
const FootballleagueFilter = (process.env.REACT_APP_BASKETBALL_FILTER || '').split(',').filter(Boolean);
const TennisleagueFilter = (process.env.REACT_APP_TENNIS_FILTER || '').split(',').filter(Boolean);
const EsportsleagueFilter = (process.env.REACT_APP_ESPORTS_FILTER || '').split(',').filter(Boolean);

// Helper function to filter data based on type and league filter
const filterData = (data, type, leagueFilter) => {
  let filtered = data.filter(item => item.Type === type);
  return leagueFilter.length !== 0 ? filtered.filter(match => leagueFilter.includes(match.League)) : filtered;
};

// Memoized selector to get filtered data based on activeCategory
export const selectFilteredData = createSelector(
  [state => state.data.data, state => state.data.activeCategory],
  (data, activeCategory) => {
    if (!data || data.length === 0) return [];

    switch (activeCategory) {
      case "CRICKET":
        return filterData(data, "CRICKET", CricketleagueFilter);
      case "BASKETBALL":
        return filterData(data, "BASKETBALL", BasketballleagueFilter);
      case "FOOTBALL":
        return filterData(data, "FOOTBALL", FootballleagueFilter);
      case "TENNIS":
        return filterData(data, "TENNIS", TennisleagueFilter);
      case "ESPORTS":
        return filterData(data, "ESPORTS", EsportsleagueFilter);
      default:
        return [
         
          
          ...filterData(data, "FOOTBALL", FootballleagueFilter),
          ...filterData(data, "ESPORTS", EsportsleagueFilter),
          ...filterData(data, "TENNIS", TennisleagueFilter),
          ...filterData(data, "BASKETBALL", BasketballleagueFilter),
          ...filterData(data, "CRICKET", CricketleagueFilter),
        ];
    }
  }
);

// Create the store
const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
  },
});

export default store;
