import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading, setData, setError } from '../storage/store';
import { getMatches } from "../service/getMatches";
import Match from "../component/matches";
import '../css/matches.css';
import matchBanner from '../assets/images/match-banner.png'
import { Helmet } from 'react-helmet';
const Matches = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await getMatches();
                if (response.length === null) {
                    throw new Error('Network Response was not OK');
                }
                const data = await response;
                dispatch(setData(data)); // Dispatch action to set data in Redux store
            } catch (error) {
                dispatch(setError(error.toString()));
            }
        };

        fetchData();
        
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Live Football Streaming - PBVSports</title>
                <meta name="description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
                <meta name="keywords" content="football, live streaming, sports, Vietnam, football leagues, live matches, football news, football highlights" />
                <meta name="author" content="PBVSports"/>
                <meta property="og:title" content="Live Football Streaming - PBVSports"/>
                <meta property="og:description" content="Stream live football matches and stay updated with the latest scores and news." />
                <meta property="og:image" content="URL_to_your_image" />
                <meta property="og:url" content="https://pbv88sports.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image"  />
                <meta name="twitter:title" content="Live Football Streaming - PBVSports" />
                <meta name="twitter:description" content="Stream live football matches and stay updated with the latest scores and news." />
                <meta name="twitter:image" content="URL_to_your_image" />
                <meta name="twitter:site" content="@yourtwitterhandle" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="matchBanner">
                <img src={matchBanner} alt='banner' style={{maxWidth:"100%", height: 'auto'}}/>
            </div>
            <div className="matches">
                
                <Match />
            </div>
        </>
    );
};

export default Matches;
