import React from 'react';
import '../css/footer.css'; // Import the CSS file for styling
import logo from '../assets/images/pbc-logo.png';
import fb from '../assets/images/fb.png';
import ig from '../assets/images/ig.png';
import tg from '../assets/images/tg.png';
import zalo from '../assets/images/zalo.png';
import { Typography } from '@mui/material';



function Footer() {

  return (
    <footer className="header">
        <div className='wrapper'>
            <div className='row'>
                <div className='copyright'>
                    <Typography variant='p' color="white"  >Copyright © 2023 PBV88</Typography>
                </div>
                <div className='social'>
                    <a href="https://www.facebook.com/PBV88Vietnam" target="_blank" rel="noopener noreferrer"><img src={fb} alt='facebook'/> </a>
                    <a href='https://www.instagram.com/pbv88casino/' target="_blank" rel="noopener noreferrer"><img src={ig} alt='instagram'/></a>
                    <a href='https://t.me/PBV88_Vietnam' target="_blank" rel="noopener noreferrer"><img src={tg} alt='telegram'/></a>
                    <a href='https://zalo.me/84926420031' target="_blank" rel="noopener noreferrer"><img src={zalo} alt='zalo'/></a>
                  
                </div>
                <div className='footer-logo'><img src={logo} alt='logo'/></div>
            </div>
        </div>
       
    </footer>
  );
}
 
export default Footer;
