import { Typography } from '@mui/material';
import React from 'react';


const LiveChat = () => {
  

  return (
    <div className='liveChat'>
      <Typography variant='p' color="white" sx={{ fontSize: '1.2rem', fontWeight:700 }}>Trò chuyện trực tiếp</Typography>
      <iframe title='livechat' src="https://www3.cbox.ws/box/?boxid=3541102&boxtag=O3nzyi"  allowtransparency="yes" allow="autoplay"  scrolling="auto"></iframe>	
    </div>
  );
};

export default LiveChat;
