import React from "react";
import { useSelector } from "react-redux";
import { selectFilteredData } from '../storage/store';
import Slider from "react-slick"; // Import Slider component
import "slick-carousel/slick/slick.css"; // Import slick CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick-theme CSS
import { Typography } from "@mui/material";
import { Icon } from "@mui/material";

const RecentMatches = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const currentItems = useSelector(selectFilteredData).slice(0, 10);
    //const { loading, error } = useSelector(state => state.data);

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={`slick-btn ${className} `} onClick={onClick}> 
                <Icon>chevron_right</Icon> 
            </button>
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={`slick-btn ${className} `} onClick={onClick}> 
                <Icon>chevron_left</Icon> 
            </button>
        );
      }

    // Slick slider settings
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            }
        ]
    };



    // if (loading) {
    //     return <div className="recent-matches"><h2>Recent Matches</h2><div className='match-container'><div className="box-spinner"></div></div></div>;
    // }

 

    return(
        <div className="recent-matches">
            <div className="wrapper">
           
            <Typography variant="h2" color="white" sx={{ fontSize: '1.8rem', fontWeight:700 }}> Các trận đấu gần đây</Typography>
                    
  
            <Slider {...settings}>
                
                {currentItems.map(item => {
                 const isBroadcast = item.IsLive === item.NowPlaying.toString() ? 0 : 1;
                    const sched = new Date(item.TimeStart);
                    const month = sched.getMonth();
                    return (
                        <div className={`recentMatchBox ${item.Type}`} data-matchid={item.MatchID} key={item.MatchID}>
                             <a href={`${process.env.REACT_APP_LIVE_ENDPOINT}/${item.MatchID}`}>
                                
                                <div className="matchinner">
                                    <span className={`isLive ${isBroadcast ? 'hide' : 'show'}`}>Live</span>
                                    <div className="schedule">
                                        <p><img src="/img/date_icon.svg" alt="date icon" /> {months[month] + " " + sched.getDate() }</p>
                                        <p><img src="/img/time_icon.svg" alt="time icon" /> {sched.toLocaleTimeString([], { timeStyle: 'short', timeZone: 'Asia/Dhaka' })}</p>
                                    </div>
                                    <div className="League">{item.League}</div>
                                    <div className="matchwrapper">
                                        <p className="teams">{item.Home}</p>
                                        <p className="versus">VS</p>
                                        <p className="teams">{item.Away}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </Slider>
            </div>
        </div>
    )
}

export default RecentMatches;
