// src/actions/leaveMatch.js
import axios from 'axios';
import { getSessionData, removeSessionData } from '../utils/session';

const leaveMatch = async (apiurl, matchId, sessionId) => {
    const startTime = getSessionData('startTime');
    const leaveTime = new Date().toISOString(); // ISO format time string

    try {
        await axios.post(`${apiurl}/viewer/leave-match/${matchId}`, {
            sessionId,
            startTime,
            leaveTime,
        });
        removeSessionData('startTime'); // Cleanup session storage
      
        
    } catch (error) {
        console.error('Error leaving match:', error);
    }
};

export default leaveMatch;
