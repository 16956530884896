import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLiveStreamByMatchid } from '../service/getLiveStreamByMatchid';
import { getMatchDatabyMatchID } from '../service/getMatchesDataByMatchId';
import eyewatcher from "../assets/images/eye-watcher.svg";
import getSessionId from '../utils/getSessionId'; // Import getSessionId
import joinMatch from '../actions/joinMatch'; // Import joinMatch
import leaveMatch from '../actions/leaveMatch'; // Import leaveMatch
import fetchViewerCount from '../actions/fetchViewerCount'; // Import fetchViewerCount
import '../css/livesteam.css';
import { Typography } from '@mui/material';
import LiveChat from '../component/livechat';
import { Helmet } from 'react-helmet';
const LiveStream = () => {
    const { matchId } = useParams();
    const [liveStreamLink, setLiveStreamLink] = useState(null);
    const [activeUsers, setActiveUsers] = useState(1);
    const apiurl = process.env.REACT_APP_APIURL;
    const [matchData, setMatchData] = useState(null);
    sessionStorage.setItem("matchId", matchId);
    useEffect(() => {
        const fetchLiveStream = async () => {
            try {
                const link = await getLiveStreamByMatchid(matchId);
                setLiveStreamLink(link);
            } catch (error) {
                console.error('Error fetching live stream link:', error);
                // Handle error state if needed
            }
        };

        const fetchMatchData = async () => {
            try {
                const data = await getMatchDatabyMatchID(matchId);
                setMatchData(data[0]);
                
            } catch (error) {
                console.error('Error fetching live stream link:', error);
                // Handle error state if needed
            }
        }
        fetchMatchData();
        fetchLiveStream();
    }, [matchId]);



    const sessionId = getSessionId(); // Get session ID using the utility

    // Using joinMatch and leaveMatch from separate files
    const handleJoinMatch = useCallback(() => joinMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);
    const handleLeaveMatch = useCallback(() => leaveMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);

    useEffect(() => {
        const updateViewerCount = () => fetchViewerCount(apiurl, matchId, setActiveUsers); // Fetch viewer count

        const intervalId = setInterval(updateViewerCount, 5000);
        handleJoinMatch().then(updateViewerCount);

        return () => {
            clearInterval(intervalId);
           // handleLeaveMatch();
        };
    }, [handleJoinMatch, handleLeaveMatch, apiurl, matchId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            handleLeaveMatch();
            event.returnValue = '';
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleLeaveMatch();
            } else if (document.visibilityState === 'visible') {
                handleJoinMatch();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleLeaveMatch, handleJoinMatch]);


    const toggleIframeFullScreen = () => {
        const iframe = document.getElementById("iframe");
        const fullscreenButton = document.querySelector(".iconfullscreen"); // Target the button
        const body = document.body;
    
        if (!iframe) {
            console.error("Iframe not found!");
            return;
        }
    
        // Check if Fullscreen API is supported (used for desktops)
        if (iframe.requestFullscreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullscreen || iframe.msRequestFullscreen) {
            if (!document.fullscreenElement) {
                // Enter Fullscreen API mode (desktop)
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.mozRequestFullScreen) { // Firefox
                    iframe.mozRequestFullScreen();
                } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                    iframe.webkitRequestFullscreen();
                } else if (iframe.msRequestFullscreen) { // IE/Edge
                    iframe.msRequestFullscreen();
                }
    
                // Add a class to indicate fullscreen mode on the button
                fullscreenButton.classList.add('in-fullscreen');
            } else {
                // Exit Fullscreen mode
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
    
                // Remove the fullscreen class from the button
                fullscreenButton.classList.remove('in-fullscreen');
            }
        } else {
            // Fallback to CSS-based fullscreen (for mobile)
            const isFullScreen = iframe.classList.contains('iframe-fullscreen');
            if (!isFullScreen) {
                // Add fullscreen class (mobile)
                iframe.classList.add('iframe-fullscreen');
                body.classList.add('iframe-fullscreen-mode');
                
                // Add the fullscreen class to the button
                fullscreenButton.classList.add('in-fullscreen');
            } else {
                // Remove fullscreen class
                iframe.classList.remove('iframe-fullscreen');
                body.classList.remove('iframe-fullscreen-mode');
                
                // Remove the fullscreen class from the button
                fullscreenButton.classList.remove('in-fullscreen');
            }
        }
    };
    
    
    
    window.addEventListener("orientationchange", () => {
        const iframe = document.getElementById("playframe");
        if (iframe && document.fullscreenElement) {
            iframe.style.width = window.innerWidth + "px";
            iframe.style.height = window.innerHeight + "px";
        }
    });
    
    return(
        <>
        <Helmet>
            <title>Live Football Streaming - PBVSports</title>
            <meta name="description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
            <meta name="keywords" content="football, live streaming, sports, Vietnam, football leagues, live matches, football news, football highlights" />
            <meta name="author" content="PBVSports"/>
            <meta property="og:title" content="Live Football Streaming - PBVSports"/>
            <meta property="og:description" content="Stream live football matches and stay updated with the latest scores and news." />
            <meta property="og:image" content="URL_to_your_image" />
            <meta property="og:url" content="https://pbv88sports.com/" />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image"  />
            <meta name="twitter:title" content="Live Football Streaming - PBVSports" />
            <meta name="twitter:description" content="Stream live football matches and stay updated with the latest scores and news." />
            <meta name="twitter:image" content="URL_to_your_image" />
            <meta name="twitter:site" content="@yourtwitterhandle" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <section className='livestream-container'>
           <div className='stream-match'>
                <div  className='stream-container'>
                    <div className='video-wrapper'>
                        <div className='video-container' id='iframe'>   
                            <div className='liveVisit'>
                                <small>LIVE</small>
                                <span><img src={eyewatcher} alt='watchers' /> {activeUsers ? activeUsers : 1}</span>
                            </div>  
                            {liveStreamLink ? (
                                <iframe src={liveStreamLink} style={{
                                    display: 'block',
                                    padding: '0px 0',
                                    border: '1px solid #000',
                                    backgroundColor: '#000',
                                    width: '100%'
                                }} width="720" height="480" title='live stream' id="playframe" allowFullScreen allow='fullscreen'></iframe>
                            ) : (
                                <div className="box-spinner"></div>
                            )}
                            <div onClick={toggleIframeFullScreen} className='fullscreenIcon'> <i className='iconfullscreen'></i></div>
                        
                        
                        </div>
                    </div>
                    {matchData ? (
                                <div className='match-data'>
                                    <div className='match-inner'>
                                        <div className='match-league'>
                                            <Typography variant='h3' color="white" sx={{ fontSize: '1.8rem', fontWeight:700, textAlign:'center', paddingBottom:'20px' }} >{matchData.League}</Typography>
                                        </div>
                                        <div className='match-details'>
                                            <div className='team'>
                                                <p>{matchData.Home}</p>
                                            </div>
                                            <div className='versus'>
                                                <p>VS</p>
                                            </div>
                                            <div className='team'>
                                                <p>{matchData.Away}</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                ) :( <div className='match-data'></div> )
                            }
                    <LiveChat></LiveChat>
                </div>
           </div>
        </section>
        </>
    )
}
export default LiveStream;