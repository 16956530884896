import React, { useEffect, useState } from 'react';
import axios from 'axios'; // For making API requests
import '../css/blog.css'; // Import the CSS file for styling
import {Icon, Typography } from "@mui/material";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const apiurl = process.env.REACT_APP_APIURL;
  useEffect(() => {
    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${apiurl}/blogs`, { timeout: 6000 }); // Adjust API endpoint
            setPosts(response.data.slice(0, 3));
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };
    fetchBlogs();
}, [apiurl]);

  const truncateContent = (content, wordLimit) => {
    const words = content.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return content;
  };

 

  return (
    <div className='blog-section'>
      <div className='wrapper'>
        <div>
          <Typography variant="h2" color="white" sx={{ fontSize: '1.8rem', fontWeight: 700 }}>Tin tức và sự kiện</Typography>
          <div className='blogs'>
            {posts.map(post => (
              <div key={post.id} className={`blog-post div${post.id}`}>
                <div className='blog-inner'>
                  <img src={`${apiurl}/uploads/${post.image || 'default.jpg'}`} alt={post.title || "Blog post image"} style={{ width: '100%', height: 'auto' }} />
                  <div className='blog-content'>
                    <h3>{post.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html:truncateContent(post.content, 8)}}></div>
                 
                    <a href={`/blog/${post.slug}`} >Đọc thêm <Icon>chevron_right</Icon> </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
