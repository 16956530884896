import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import Home from './pages/home';
import LiveStream from './pages/livestream';
import Live from './pages/live';
import Header from './component/header';
import './css/style.css';
import { ThemeProvider, createTheme } from "@mui/material";
import Footer from './component/footer';
import Matches from './pages/matches';
import leaveMatch from './actions/leaveMatch'; // Import the leaveMatch function
import Blog from './pages/blog';
import BlogDetail from './pages/blogdetail';
import MatchStats from './pages/matchstats';
import BlogPost from './pages/blog-post';
import BlogList from './pages/blog-list';
import PrivateRoute from './component/privateRoute';
import Login from './pages/login';
import { green, blue, grey } from '@mui/material/colors';
import LiveSettings from './pages/live-settings';
import { getSessionData, removeSessionData } from '../src/utils/session';
import BannerSettings from './pages/banner-setting';

const matchId = sessionStorage.getItem('matchId') || null; // Get matchId from sessionStorage
const apiurl = process.env.REACT_APP_APIURL;

function NavigationHandler({ apiurl, matchId, handledLeave }) {
  const location = useLocation();

  useEffect(() => {
    const handleNavigationChange = () => {
      // Delay the check to avoid race conditions
      setTimeout(() => {
        if (location.pathname === '/') {
          console.log('You are on the home page!');
          removeSessionData('matchId');
          handledLeave(); // Leave the match if the user navigates to the home page
        } else {
          console.log('You are not on the home page.');
        }
      }, 1000);
    };

    // Call handleNavigationChange initially and on location change
    handleNavigationChange();

    // Add event listeners for navigation events (such as browser back/forward or touch events)
    window.addEventListener('popstate', handleNavigationChange);
    window.addEventListener('touchstart', handleNavigationChange);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
      window.removeEventListener('touchstart', handleNavigationChange);
    };
  }, [location, handledLeave]); // Ensure the effect runs on location change or when handledLeave changes

  return null; // This component does not render any UI
}

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Montserrat", 'system-ui'
      ].join(','),
      fontWeightBold: 700,
    },
    palette: {
      primary: {
        main: "#ffbb00",
      },
      secondary: {
        main: grey[500],
      },
      tertiary: {
        main: "#421414",
      },
    },
  });



  // Callback to handle leaving the match
  const handledLeave = useCallback(() => {
    if (matchId) {
      leaveMatch(apiurl, matchId, sessionStorage.getItem('sessionId'))
        .then(() => {
          sessionStorage.removeItem('matchId'); // Remove matchId after leaving the match
        })
        .catch((error) => console.error('Error leaving match:', error));
    }
  }, [apiurl, matchId]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <NavigationHandler apiurl={apiurl} matchId={matchId} handledLeave={handledLeave} />
        <Header></Header>
        <div id='main-body' className='body-collapse'>
          <div className='main-wrapper'>
            <Routes>
                <Route path='/' element={<Home />}/>
                <Route path='/matches' element={<Matches/>}/>
                <Route path='/matches/:category/:page' element={<Matches/>}/>
                <Route path='/live-stream/:matchId' element={<LiveStream />}/>
                <Route path='/live' element={<Live />}/>
                <Route path='/blog' element={<Blog />}/>
                <Route path='/rs-pbv-login' element={<Login />}/>
                <Route path='/blog/:slug' element={<BlogDetail />}/>
                <Route path='/match-stats' element={<PrivateRoute> <MatchStats/> </PrivateRoute>}/>
                <Route path='/blog-post' element={<PrivateRoute> <BlogPost />  </PrivateRoute>}/>
                <Route path='/blog-post/edit/:id' element={<PrivateRoute><BlogPost />  </PrivateRoute>}/>
                <Route path='/blog-list' element={<PrivateRoute> <BlogList /></PrivateRoute>}/>
                <Route path='/live-settings' element={<PrivateRoute> <LiveSettings /></PrivateRoute>}/>
                <Route path='/banner-settings' element={<PrivateRoute> <BannerSettings /></PrivateRoute>}/>
            </Routes>
          </div>
          <Footer></Footer>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
