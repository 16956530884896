import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For making API requests
import { useNavigate } from 'react-router-dom';
import '../css/blog.css';
import { Button, ButtonGroup, Icon, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [selectedBlogs, setSelectedBlogs] = useState([]);
    const [showDeleted, setShowDeleted] = useState(false); // State for showing deleted blogs
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiurl = process.env.REACT_APP_APIURL;

    // Fetch blog posts from the server
    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${apiurl}/blogs`); // Adjust API endpoint
            setBlogs(response.data.filter(item => item.is_delete === 0));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setError('Failed to load blogs');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    // Fetch only deleted blogs
    const fetchDeletedBlogs = async () => {
        try {
            const response = await axios.get(`${apiurl}/blogs`); // Adjust API endpoint to fetch deleted blogs
            setBlogs(response.data.filter(item => item.is_delete === 1));
        } catch (error) {
            console.error('Error fetching deleted blogs:', error);
        }
    };

    // Handle individual delete (move to trash by setting is_delete to 1)
    const handleDelete = async (id) => {
        try {
            // Update the blog's is_delete field to 1 (mark as deleted)
            await axios.put(`${apiurl}/blogs/${id}`, { is_delete: 1 });
            fetchBlogs(); // Re-fetch blogs after deletion
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };

    // Handle bulk status update
    const handleBulkStatusUpdate = async (status, isDelete = 0) => {
        try {
            if (selectedBlogs.length === 0) {
                console.error('No blogs selected for bulk update');
                return;
            }

            // Make a PUT request to the /bulk/status API endpoint with status and is_delete
            await axios.put(`${apiurl}/blogs/bulk/status`, {
                ids: selectedBlogs, // Array of selected blog IDs
                status: status,     // The new status to apply (e.g., 'publish', 'draft')
                is_delete: isDelete // Whether it's marked for deletion (1) or active (0)
            });

            fetchBlogs(); // Re-fetch the blogs after status update
            setSelectedBlogs([]); // Clear selected items after bulk action
        } catch (error) {
            console.error('Error bulk updating status:', error);
        }
    };

    // Handle bulk publish action
    const handleBulkPublish = () => {
        handleBulkStatusUpdate('Publish', 0); // Set is_delete to 0 for publishing
    };

    // Handle bulk draft action
    const handleBulkDraft = () => {
        handleBulkStatusUpdate('Draft', 0); // Set is_delete to 0 for setting as draft
    };

    // Handle bulk delete action
    const handleBulkDelete = () => {
        handleBulkStatusUpdate('Trash', 1); // Set is_delete to 1 for deletion
    };

    // Handle checkbox toggle
    const handleCheckboxChange = (id) => {
        if (selectedBlogs.includes(id)) {
            setSelectedBlogs(selectedBlogs.filter(blogId => blogId !== id));
        } else {
            setSelectedBlogs([...selectedBlogs, id]);
        }
    };

    // Toggle between all blogs and deleted blogs
    const toggleShowDeleted = () => {
        if (showDeleted) {
            setShowDeleted(false);
            fetchBlogs(); // Re-fetch all blogs when toggling back from trash view
        } else {
            setShowDeleted(true);
            fetchDeletedBlogs(); // Fetch only deleted blogs
        }
    };

    if (loading) {
        return <div className="loading"><div className="box-spinner"></div></div>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="blog-container">
            <div className="blog-list">
                <div className="blog-header">
                    <h1>Blogs</h1>
                    <ButtonGroup variant="contained" sx={{ marginTop: "20px" }} aria-label="Basic button group">
                        <Button onClick={() => navigate('/blog-post')}>
                            <Icon>add</Icon>Add New
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="blog-wrapper">
                    {/* Bulk Action Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Button onClick={toggleShowDeleted} style={{ padding: 10 }} color="primary">
                                {showDeleted ? 'Show All Blogs' : 'Show Trash Blogs'}
                            </Button>
                        </div>

                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Button
                                onClick={handleBulkPublish}
                                disabled={selectedBlogs.length === 0}
                                color="primary"
                                variant="contained"
                            >
                                Publish
                            </Button>
                            <Button
                                onClick={handleBulkDraft}
                                disabled={selectedBlogs.length === 0}
                                color="secondary"
                                variant="contained"
                            >
                                Set as Draft
                            </Button>
                            <Button
                                onClick={handleBulkDelete}
                                disabled={selectedBlogs.length === 0}
                                color="error"
                                variant="contained"
                            >
                                Delete
                            </Button>
                        </div>
                    </div>

                    {/* Blog List */}
                    <div className="bloglist-container">
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                            <TableHead>
                                <TableCell width={"5%"}>
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            setSelectedBlogs(e.target.checked ? blogs.map(blog => blog.id) : [])
                                        }
                                        checked={selectedBlogs.length === blogs.length && blogs.length > 0}
                                    />
                                </TableCell>
                                <TableCell width={"50%"}>Title</TableCell>
                                <TableCell width={"15%"}>Author</TableCell>
                                <TableCell width={"10%"}>Status</TableCell>
                                <TableCell width={"20%"}>Actions</TableCell>
                            </TableHead>
                            <TableBody>
                                {blogs.map(blog => (
                                    <TableRow key={blog.id}>
                                        <TableCell width={"5%"}>
                                            <input
                                                type="checkbox"
                                                checked={selectedBlogs.includes(blog.id)}
                                                onChange={() => handleCheckboxChange(blog.id)}
                                            />
                                        </TableCell>
                                        <TableCell width={"50%"}>{blog.title}</TableCell>
                                        <TableCell width={"15%"}>{blog.author}</TableCell>
                                        <TableCell width={"10%"}>{!blog.is_delete ? blog.status : 'Trash'}</TableCell>
                                        <TableCell width={"20%"}>
                                            <ButtonGroup>
                                                <Button color='tertiary' onClick={() => navigate(`/blog-post/edit/${blog.id}`)}>Edit</Button>
                                                <Button color='tertiary' onClick={() => handleDelete(blog.id)}>Delete</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogList;
