
import axios from 'axios';

export const getMatches = async () => {
    const tokenkey = process.env.REACT_APP_TOKENKEY;
    const apiurl = process.env.REACT_APP_APIURL;
    const timeout = 6000; // 6 seconds

    try {
        const response = await axios.get(`${apiurl}/matches?key=${tokenkey}`, { timeout });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error so it can be handled by the caller
    }
};
