import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BannerDisplay = () => {
    const [bannerData, setBannerData] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state
    const apiUrl = process.env.REACT_APP_APIURL;

    useEffect(() => {
        const fetchBannerData = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axios.get(`${apiUrl}/banner/api/get-banner`);
                setBannerData(response.data.data);
            } catch (error) {
                console.error('Error fetching banner data:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchBannerData();
    }, [apiUrl]);

    if (loading) {
        return (
            <div className='loading'><div className="box-spinner"></div></div>
        );
    }

    if (!bannerData) return null; // Don't render anything if no data is fetched yet

    return (
        <div className="banner-display">
            {/* Display the main banner image */}
            {bannerData.banner_image && (
                <img src={`${apiUrl}${bannerData.banner_image}`} alt="Banner" className="banner-image" />
            )}
            
            <div className='banner-Text'>
                {/* Display the banner text image if available */}
                {bannerData.banner_text_image && (
                    <img src={`${apiUrl}${bannerData.banner_text_image}`} alt="Banner Text" className="banner-text-image" />
                )}

                {/* Display button link if available */}
                {bannerData.button_link && (
                    <a href={`live-stream/${bannerData.button_link}` }  className="banner-button">
                        Watch Live
                    </a>
                )}
            </div>
        </div>
    );
};

export default BannerDisplay;
