import React from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
    const authToken = localStorage.getItem('authToken');

    // Simple loader (you can expand this to a more complex logic if needed)
    if (!authToken) {
        return <Navigate to="/rs-pbv-login" replace />;
    }

    return children;
};

export default PrivateRoute;
