import axios from 'axios';

export const getCurrentLive = async () => {
    const tokenkey = process.env.REACT_APP_TOKENKEY;
    const sportstype = process.env.REACT_APP_SPORTSTYPE;
    const apiurl = process.env.REACT_APP_APIURL;
    const timeout = 6000; // 6 seconds

    try {
        const req = `${apiurl}/getCurrentMatch?key=${tokenkey}&sportstype=${sportstype}`;
        const response = await axios.get(req, { timeout });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error so it can be handled by the caller
    }
};
