import React, { useEffect } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { setLoading, setData, setError  } from '../storage/store';
import { getMatches } from "../service/getMatches";
import '../css/banner.css';
import RecentMatches from "../component/recentMatches";

import Blogs from "../component/blogs";
import { Helmet } from 'react-helmet';
import downloadImage from '../assets/images/download-1.png';
import qrcode from '../assets/images/qr-code.png';
import coverPhoto from '../assets/images/pbv-cover-photo.jpg'
import BannerDisplay from '../component/BannerDisplay';
const Home = () => {
   const dispatch = useDispatch();
   const { loading } = useSelector(state => state.data);
    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await getMatches()
                if (response.length === null) {
                    throw new Error('Network Response was not OK')
                }
                const data = await response;
                dispatch(setData(data)); // Dispatch action to set data in Redux store
            } catch (error) {
                dispatch(setError(error.toString()));
            }
        };
        fetchData();
    }, [dispatch]);

    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    return (
        <>
     
            <Helmet>
                <title>Live Football Streaming - PBV88</title>
                <meta name="description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
                <meta name="keywords" content="football, live streaming, sports, Vietnam, football leagues, live matches, football news, football highlights" />
                <meta name="author" content="PBVSports"/>
                <meta property="og:title" content="Live Football Streaming - PBVSports"/>
                <meta property="og:description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
                <meta property="og:image" content="https://pbv88sports.com/static/media/pbv-cover-photo.67075c5b6963e1e643ae.jpg" />
                <meta property="og:url" content="https://pbv88sports.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="https://pbv88sports.com/static/media/pbv-cover-photo.67075c5b6963e1e643ae.jpg"  />
                <meta name="twitter:title" content="Live Football Streaming - PBVSports" />
                <meta name="twitter:description" content="Watch live football leagues and matches on PBVSports. Stay updated with the latest scores, news, and highlights from top football leagues around the world." />
                <meta name="twitter:image" content="https://pbv88sports.com/static/media/pbv-cover-photo.67075c5b6963e1e643ae.jpg" />
                <meta name="twitter:site" content="@yourtwitterhandle" />
                <meta name="twitter:card" content="https://pbv88sports.com/static/media/pbv-cover-photo.67075c5b6963e1e643ae.jpg"  />
            </Helmet>
            
            <section className="banner-sec">
                <BannerDisplay></BannerDisplay>
            </section>

            <section >
                <RecentMatches/>
                <a href='/matches' className='btn-viewall'>Xem tất cả</a>
            </section>
            <section className='download-section'>
                <div className='wrapper'>
                    <div className='download-content'>
                        <div className='download-image'>
                            <img src={downloadImage} alt='pbv download image' />
                        </div>
                        <div className='download-copy'>
                            <h2>Tải xuống ứng dụng của chúng tôi</h2>
                            <p>Nếu bạn đang tìm kiếm ứng dụng cá cược di động tốt nhất, đừng nên tìm nữa. Ứng dụng của chúng tôi mang đến một cách nhanh chóng và thuận tiện để đặt cược với một loạt các tùy chọn thị trường thể thao trước và khi trận đấu đang diễn ra. Với tính năng "vân tay" Touch ID, đăng nhập trở nên nhanh chóng hơn bao giờ hết.</p>

                            <p>Ứng dụng mang đến cho bạn tự do để tận hưởng các trò chơi tuyệt vời của chúng tôi bất kể bạn đang ở đâu</p>

                            <p>Tải xuống và tận hưởng ngay bây giờ</p>
                            <img src={qrcode} alt='andriod qr code' style={{width:'190px', height: 'auto', marginTop: '15px'}}/>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Blogs/>
            </section>
            
        </>
    );
}
export default Home;