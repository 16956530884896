import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for Quill
import { useDropzone } from 'react-dropzone'; // Import react-dropzone
import '../css/blog-post.css';
import { Button, ButtonGroup, Icon, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom'; // For getting URL params and navigation

const BlogPost = () => {
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        content: '',
        dateCreated: new Date().toISOString().split('T')[0], // Set current date automatically
        slug: '',
        metaDescription: '',
        metaKeywords: '',
        image: null,
        category: '' || 'News',
        status: 'Draft', // Default value for status
    });

    const { id } = useParams(); // Get the blog post id from the URL if available
    const navigate = useNavigate(); // Used for navigation
    const [isEditing, setIsEditing] = useState(false); // Track if we are in edit mode
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_APIURL; // Use dynamic API URL from environment variables
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(false)
        if (id) {
            // If there is an id in the URL, fetch the existing blog data for editing
            setIsEditing(true);
            
            const fetchBlogPost = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/blogs/${id}`);
                    const blog = response.data;
                    setFormData({
                        title: blog.title,
                        author: blog.author,
                        content: blog.content,
                        dateCreated: blog.dateCreated.split('T')[0], // Format the date
                        slug: blog.slug,
                        metaDescription: blog.metaDescription,
                        metaKeywords: blog.metaKeywords,
                        image: blog.image, // Keep the existing image as null; allow the user to upload a new one if needed
                        status: blog.status, // Set status or default to 'Draft'
                        category: blog.category, // Pre-fill category when editing
                    });
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching blog post data:', error);
                    alert('Error fetching blog post data');
                    setError('Failed to load blog post stats');
                    setLoading(false);
                }
            };

            fetchBlogPost();
        }
    }, [id, apiUrl]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleContentChange = (content) => {
        setFormData((prevData) => ({ ...prevData, content }));
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setFormData((prevData) => ({ ...prevData, image: file }));
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept image files only
        multiple: false,   // Only allow one file at a time
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object for file upload
        const form = new FormData();
        form.append('title', formData.title);
        form.append('author', formData.author);
        form.append('content', formData.content); // Use content from WYSIWYG editor
        form.append('dateCreated', formData.dateCreated); // Use the current date or selected date
        form.append('slug', formData.slug);
        form.append('metaDescription', formData.metaDescription);
        form.append('metaKeywords', formData.metaKeywords);
        form.append('status', formData.status); // Append the status field
        form.append('category', formData.category); // Add category to form data


        if (formData.image) {
            form.append('image', formData.image); // Attach image file only if a new one is uploaded
        }
        console.log(form);
        try {
            if (isEditing) {
                // If we are editing, update the blog post
                await axios.put(`${apiUrl}/blogs/${id}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert('Blog post updated successfully!');
            } else {
                // If we are not editing, create a new blog post
                
                await axios.post(`${apiUrl}/blogs/create`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert('Blog post created successfully!');
            }

            navigate('/blog-list'); // Redirect to the blog list page
            window.location.reload(); // Refresh the page to update the blog list
        } catch (error) {
            console.error('Error submitting form', error);
           alert('Error saving blog post');
        }
    };

    const handleBackClick = () => {
        navigate('/blog-list'); // Navigate to the blog list page
    };

    const handleAddNewClick = () => {
        navigate('/blog-post'); // Navigate to add a new blog post
    };


    
    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    if (error) {
        return <p>{error}</p>;
    }


    return (
        <>
            <div className='blog-post-container'>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {isEditing ? (
                        <ButtonGroup variant="contained" sx={{ marginBottom: "20px" }} aria-label="Basic button group">
                            <Button onClick={handleBackClick}><Icon>arrow_back</Icon> Back</Button>
                            <Button onClick={handleAddNewClick}><Icon>add</Icon>Add New</Button>
                        </ButtonGroup>
                    ) : (
                        <ButtonGroup variant="contained" sx={{ marginBottom: "20px" }} aria-label="Basic button group">
                            <Button onClick={handleBackClick}><Icon>arrow_back</Icon> Back</Button>
                        </ButtonGroup>
                    )}
                    <Typography sx={{ color: "#fff", fontSize: "2rem", fontWeight: 700 }} variant='h3'>
                        {isEditing ? 'Edit Post' : 'Create Post'}
                    </Typography>
                    <div className="container">
                        <div className="main">
                            <div className='form-control'>
                                <label>Title</label>
                                <input type="text" name="title" value={formData.title} onChange={handleChange} required />
                            </div>
                            <div className='form-control'>
                                <label>Slug</label>
                                <input type="text" name="slug" value={formData.slug} onChange={handleChange} required />
                            </div>
                            <div className='form-control'>
                                <label>Content</label>
                                <ReactQuill
                                    theme="snow"
                                    value={formData.content}
                                    onChange={handleContentChange} // Use ReactQuill for content input
                                    required
                                />
                            </div>
                            <div className='form-control'>
                                <label>Meta Description</label>
                                <input type="text" name="metaDescription" value={formData.metaDescription} onChange={handleChange} required />
                            </div>
                            <div className='form-control'>
                                <label>Meta Keywords</label>
                                <input type="text" name="metaKeywords" value={formData.metaKeywords} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="sidebar">
                            <div className='form-control'>
                                <label>Status</label>
                                <select name="status" value={formData.status} onChange={handleChange} required>
                                    <option value="Draft">Draft</option>
                                    <option value="Publish">Publish</option>
                                </select>
                            </div>
                            <div className='form-control'>
                                <label>Date Created</label>
                                <input
                                    type="date"
                                    name="dateCreated"
                                    value={formData.dateCreated}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='form-control'>
                                <label>Category</label>
                                <select name="category" value={formData.category} onChange={handleChange} required>
                                    <option value="News">News</option>
                                    <option value="Updates">Updates</option>
                                </select>
                            </div>

                            <div className='form-control'>
                                <label>Author</label>
                                <input type="text" name="author" value={formData.author} onChange={handleChange} required />
                            </div>
                            <div className='form-control'>
                                <label>Upload Image</label>
                    
                                <div
                                    {...getRootProps({ className: 'dropzone' })}
                                    style={{
                                        border: '2px dashed #cccccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (
                                            <p>Drag 'n' drop an image here, or click to select one</p>
                                        )
                                    }
                                </div>
                                {formData.image && <p>Selected file: {formData.image.name}</p>}
                            </div>
                            <button type="submit">{isEditing ? 'Update Blog Post' : 'Create Blog Post'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default BlogPost;
